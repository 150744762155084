.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-image: url("../public/wavey-fingerprint.svg");
  background-position: center;
  background-size: cover;
  animation: moveBackground 20s linear infinite alternate;
}

@keyframes moveBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.button {
  width: 120px;
  height: 120px;
  font-size: 25px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  border: solid 1px #000000;
}

/* Remove visual changes after touch event */
.button:focus,
.button:active {
  outline: none;
  background-color: #ffffff; /* Ensure background color remains consistent */
  transform: none; /* Ensure no scale transformation occurs */
}

/* Hover styles for non-touch devices */
.button:hover {
  background-color: #393b3d;
}

/* Ensures button appearance is not altered by active state */
.button:active {
  background-color: #39393a;
  transform: scale(0.98);
}

.box {
  width: 100px; /* Increased width */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.8px);
  -webkit-backdrop-filter: blur(5.8px);
  border: 1px solid rgba(255, 255, 255, 0.74);
}

.box h1 {
  margin: 0 0 20px;
  font-size: 35px;
}

.img {
  padding-bottom: 45px;
}

.App-link {
  color: #7f8062;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Base styles for larger screens */
.container {
  display: flex;
  flex-wrap: wrap;
}

.item {
  flex: 1 1 30%;
  margin: 10px;
}

.box {
  width: 100px;
  padding: 120px;
}

/* Styles for loading and error messages */
.loading-message,
.error-message {
  color: #ffffff; /* White color */
  font-size: 1.2rem; /* Adjust font size as needed */
  margin: 10px 0; /* Space above and below the text */
}

/* Styles for tablets */
@media (max-width: 768px) {
  .item {
    flex: 1 1 45%;
  }
  .navbar {
    flex-direction: column;
  }

  .nav-links {
    flex-direction: column;
  }
}

/* Styles for mobile phones */
@media (max-width: 480px) {
  .item {
    flex: 1 1 100%;
  }
  body {
    font-size: 14px;
  }

  .menu-icon {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
  }

  .nav-links.show {
    display: flex;
  }
}

/* Styles for larger screens */
@media (min-width: 1024px) {
  .box {
    width: 140px;
    padding: 130px;
    height: 320px;
  }
}

/* Disable hover touch devices */
@media (pointer: coarse) {
  .button:hover {
    background-color: #ffffff;
  }
}
